import _ from 'lodash';
import { AppNav } from '../../../../../../core';
import text from './resources/locale/en.json';

function couponDropdownValidator(value) {
    return value && value[0] && !value[0].invalid;
}

class What {
    constructor(
        $state,
        $q,
        $timeout,
        crConfig,
        crConstants,
        crLocaleService,
        crAnalyticsService,
        crEntityService,
        crErrorLoggingService,
        crPoiService
    ) {
        this.$state = $state;
        this.$q = $q;
        this.$timeout = $timeout;
        this.crConfig = crConfig;
        this.crConstants = crConstants;
        this.crLocaleService = crLocaleService;
        this.crAnalyticsService = crAnalyticsService;
        this.crEntityService = crEntityService;
        this.crErrorLoggingService = crErrorLoggingService;
        this.crPoiService = crPoiService;

        this.text = text;
        this.couponDropdownValidator = couponDropdownValidator;
    }

    $onInit() {
        this.locale = this.crLocaleService.getLocale(this.$state.params.venueId, this.$state.params.locale, !this.edit);
        this.showDropdown =
            this.crConfig.customer.localizationEnabled && this.locale.list && this.locale.list.length > 1;
        this.refreshLocaleStatus();

        this.pushOptions = [
            {
                label: this.text.pushNotification,
                name: 'push-notifications',
                checked: !!this.experience.content.push,
            },
        ];

        this.smsOptions = [
            {
                label: this.text.smsNotification,
                name: 'sms-notifications',
                checked: !!this.experience.content.sms,
            },
        ];

        this.addUtmOptions = [
            {
                label: this.text.showUtm,
                name: 'add-utm-options',
                checked: !!this.showUtm
            }
        ];

        this.appDisplayOptions = [
            {
                label: this.text.inboxOnly,
                value: false,
            },
            {
                label: this.text.inboxAndHome,
                value: true,
            },
        ];

        this.linkTypeOptions = this.initializeLinkTypeOptions();

        this.appScreenOptions = this.initializeAppScreenOptions();

        this.pushMessages = this.experience.content.push || {};

        this.smsMessages = this.experience.content.sms || {};

        this.initializeMenuLinks().then(() => this.initializeLinks());

        this.initializeCoupons();

        this.shouldShowUtm = false

        this.utmCampaign = '_utm_campaign'
        this.utmUrl = '_utm_url'
        this.baseUrl = '_base_url'
        this.showUtm = '_show_utm'
    }

    initializeLinks() {
        this.experience.content.links.forEach((link) => {
            this.addDropdownOptionsToLink(link);

            // Can you tell the difference between a loqueue addon and a ride? AppNav can.
            if (link.appNav && link.appNav[this.locale.current]) {
                const appNav = AppNav.fromString(link.appNav[this.locale.current]);
                link.appNavType = appNav.type;
            }

            link.typeOptions.forEach((typeOption) => {
                typeOption.isSelected = link.type === typeOption.value;
                if (!typeOption.isSelected && link.appNavType) {
                    typeOption.isSelected = link.appNavType === typeOption.appNavType;
                }
            });

            const applySelection = (option) => {
                option.isSelected = option.id === link.resourceId;
            };
            if (link.type === 'POI') {
                link.poiOptions.forEach(applySelection);
            } else if (link.type === 'EVENT') {
                link.eventOptions.forEach(applySelection);
            } else if (link.type === 'PRODUCT') {
                link.productOptions.forEach(applySelection);
            } else if (link.type === 'APP_SCREEN') {
                link.appScreenOptions.forEach(applySelection);
            } else if (link.type === 'DINING_MENU') {
                link.menuOptions.forEach(applySelection);
            } else if (link.type === 'LOQUEUE') {
                link.loQueueProductOptions.forEach(applySelection);
                link.poisWithRidesOptions.forEach(applySelection);
            }
        });
    }

    initializeLinkTypeOptions() {
        const linkTypes = [
            {
                label: this.text.poiType,
                value: 'POI',
            },
            {
                label: this.text.eventType,
                value: 'EVENT',
            },
            {
                label: this.text.webType,
                value: 'WEB',
                appNavType: 'webview',
            },
            {
                label: this.text.productType,
                value: 'PRODUCT',
            },
        ];

        if (this.poisWithRides !== null && this.loQueueProducts !== null) {
            linkTypes.push({
                label: this.text.loQueueProductType,
                value: 'LOQUEUE',
                appNavType: 'loQueueProductDetails',
            });
            linkTypes.push({
                label: this.text.loQueueRideType,
                value: 'LOQUEUE',
                appNavType: 'loQueueRideDetails',
            });
        }

        linkTypes.push({
            label: this.text.menuType,
            value: 'DINING_MENU',
        });

        linkTypes.push({
            label: this.text.appScreenType,
            value: 'APP_SCREEN',
        });

        return linkTypes;
    }

    initializeAppScreenOptions() {
        let appScreens = [
            { id: 'GUEST_PREF_VENUE', name: this.text.labelGuestPreferencesVenue },
            { id: 'GUEST_PREF_BRAND', name: this.text.labelGuestPreferencesBrand },
        ];

        if (this.hasAppLinksConfigured()) {
            appScreens = appScreens.concat(this.crConfig.customer.appLinks);
        }

        return appScreens;
    }

    hasAppLinksConfigured() {
        return this.crConfig.customer.appLinks && this.crConfig.customer.appLinks.length > 0;
    }

    // A menu link is actually a link to a fully qualified place id
    // where the place is a commerce-enabled POI
    initializeMenuLinks() {
        const queryParams = {
            public: 'true',
            commerceEnabled: 'true',
            venueId: this.$state.params.venueId,
        };

        return this.crPoiService
            .getPois(queryParams)
            .then((res) => res.content)
            .then((places) => {
                this.menuOptions = places.map((place) => {
                    const fqid = `poi:${this.$state.params.venueId}.${place.id}`;
                    return {
                        id: fqid,
                        name: place.label,
                    };
                });
            })
            .finally(() => {
                if (!this.menuOptions || this.menuOptions.length === 0) {
                    // Remove menus from options
                    this.linkTypeOptions = this.linkTypeOptions.filter((opt) => opt.value !== 'DINING_MENU');
                }
            });
    }

    get hasActiveMenus() {
        return this.menuOptions && this.menuOptions.length > 0;
    }

    // Populate dropdowns for existing coupons in the experience
    initializeCoupons() {
        // Create map of known coupons
        this.couponMap = {};
        this.coupons.forEach((coupon) => {
            this.couponMap[coupon.id] = coupon;
        });

        if (this.experience.content.coupons) {
            this.experience.content.coupons.forEach((coupon) => {
                // If the coupon doesn't exist, take note of that
                if (!this.couponMap[coupon.resourceId]) {
                    console.warn(`Coupon ${coupon.resourceId} doesn't exist - it was probably archived`);
                    // Mark the coupon as invalid
                    coupon.invalid = true;
                    // Place the invalid coupon into the list/map
                    const invalidCoupon = { id: coupon.resourceId, invalid: true, name: this.text.invalidCoupon };
                    this.coupons.push(invalidCoupon);
                    this.couponMap[invalidCoupon.id] = invalidCoupon;
                    // Dirty the form so that it's marked as invalid
                    this.dirtyForm();
                }
            });
        }

        if (this.experience.content.coupons) {
            this.experience.content.coupons.forEach((coupon) => {
                // Populate options for dropdown
                coupon.couponOptions = _.cloneDeep(this.coupons);
                coupon.couponOptions.forEach((couponOption) => {
                    couponOption.isSelected = coupon.resourceId === couponOption.id;
                });
            });
        }
    }

    get isDefaultLocale() {
        return this.locale.current === this.locale.default;
    }

    dirtyForm() {
        this.form.$setDirty();
    }

    onNameUpdate(event) {
        this.experience.name = event.model;
    }

    onHeadlineUpdate(event) {
        if (_.isEmpty(event.model)) {
            _.unset(this.experience, `content.title.${this.locale.current}`);
        } else {
            this.experience.content.title[this.locale.current] = event.model;
        }
        this.refreshLocaleStatus();
    }

    onBodyUpdate(event) {
        if (_.isEmpty(event.target.value)) {
            _.unset(this.experience, `content.body.${this.locale.current}`);
        } else {
            this.experience.content.body[this.locale.current] = event.target.value;
        }
        this.refreshLocaleStatus();
    }

    onLocaleUpdate(event) {
        this.locale.current = event.model[0].id;
        this.crAnalyticsService.track('Language Selected', { language: event.model[0].label });
        this.onModelChanged();
    }

    onPushUpdate(event) {
        this.pushOptions[0].checked = event.model[this.pushOptions[0].name];
        const enabled = event.model[this.pushOptions[0].name];

        if (enabled) {
            this.experience.content.push = this.pushMessages;
        } else {
            delete this.experience.content.push;
        }

        this.$timeout(() => {
            this.dirtyForm();
            this.refreshLocaleStatus();
        });
    }

    onSmsUpdate(event) {
        this.smsOptions[0].checked = event.model[this.smsOptions[0].name];
        const enabled = event.model[this.smsOptions[0].name];

        if (enabled) {
            this.experience.content.sms = this.smsMessages;
        } else {
            delete this.experience.content.sms;
        }

        this.$timeout(() => {
            this.dirtyForm();
            this.refreshLocaleStatus();
        });
    }

    onPushMessageUpdate(event) {
        if (_.isEmpty(event.target.value)) {
            _.unset(this.pushMessages, this.locale.current);
        } else {
            this.pushMessages[this.locale.current] = event.target.value;
        }
        this.experience.content.push = this.pushMessages;
        this.refreshLocaleStatus();
    }

    onSmsMessageUpdate(event) {
        if (_.isEmpty(event.target.value)) {
            _.unset(this.smsMessages, this.locale.current);
        } else {
            this.smsMessages[this.locale.current] = event.target.value;
        }
        this.experience.content.sms = this.smsMessages;
        this.refreshLocaleStatus();
    }

    get isInvalidForm() {
        const hasTitle = !!this.experience.content.title[this.locale.current];
        const hasBody = !!this.experience.content.body[this.locale.current];
        const hasPushMessage = !this.experience.content.push || !!this.experience.content.push[this.locale.current];

        return (
            this.isDefaultLocale && (!hasTitle || !hasBody || !hasPushMessage || !this.linksValid || !this.couponsValid)
        );
    }

    refreshLocaleStatus() {
        this.locale.list = this.locale.list.map((locale) => {
            let warning = false;
            const { content } = this.experience;

            if (!content.title[locale.id] || !content.body[locale.id]) {
                warning = true;
            }

            if (this.experience.content.push && !this.experience.content.push[locale.id]) {
                warning = true;
            }

            const invalidLink = this.experience.content.links.some(
                (link) =>
                    !link.type || !link.label[locale.id] || (!_.isEmpty(link.link) && !_.get(link, `link.${locale.id}`))
            );

            if (invalidLink) {
                warning = true;
            }

            locale.hasStatusWarning = warning;

            return locale;
        });
        this.onModelChanged();
    }

    addLink() {
        // Create a shallow copy
        const newLinks = this.experience.content.links.map((n) => n);
        newLinks.push(this.addDropdownOptionsToLink({ label: {}, appNav: {} }));
        this.experience.content.links = newLinks;

        this.$timeout(() => {
            this.dirtyForm();
            this.refreshLocaleStatus();
        });
    }

    // These options drive the dropdown menus
    addDropdownOptionsToLink(link) {
        link.typeOptions = _.cloneDeep(this.linkTypeOptions);
        link.poiOptions = _.cloneDeep(this.pois);
        link.eventOptions = _.cloneDeep(this.events);
        link.productOptions = _.cloneDeep(this.products);
        link.appScreenOptions = _.cloneDeep(this.appScreenOptions);
        link.menuOptions = _.cloneDeep(this.menuOptions);
        link.loQueueProductOptions = _.cloneDeep(this.loQueueProducts);
        link.poisWithRidesOptions = _.cloneDeep(this.poisWithRides);
        return link;
    }

    get linksValid() {
        for (let i = 0; i < this.experience.content.links.length; i++) {
            const typeControl = this.form[`link-type-${i}`];
            if (typeControl && !typeControl.$valid) {
                return false;
            }

            const labelControl = this.form[`link-label-${i}`];
            if (labelControl && !labelControl.$valid) {
                return false;
            }

            const urlControl = this.form[`link-link-${i}`];
            if (urlControl && !urlControl.$valid) {
                return false;
            }

            const poiControl = this.form[`link-poi-${i}`];
            if (poiControl && !poiControl.$valid) {
                return false;
            }

            const eventControl = this.form[`link-event-${i}`];
            if (eventControl && !eventControl.$valid) {
                return false;
            }
        }

        return true;
    }

    onLinkLabelUpdate(event, index) {
        if (_.isEmpty(event.model)) {
            _.unset(this.experience, `content.links.${index}.label.${this.locale.current}`);
        } else {
            const link = this.experience.content.links[index];
            link.label[this.locale.current] = event.model;
        }
        this.refreshLocaleStatus();
    }

    onLinkTypeUpdate(event, index) {
        const link = this.experience.content.links[index];
        const selection = event.model[0].value;
        delete link.resourceId;

        const unSelect = (option) => {
            option.isSelected = false;
        };

        if (selection === 'WEB') {
            link.link = {};
        } else if (selection === 'EVENT') {
            delete link.link;
            link.eventOptions.forEach(unSelect);
        } else if (selection === 'POI') {
            delete link.link;
            link.poiOptions.forEach(unSelect);
        } else if (selection === 'PRODUCT') {
            delete link.link;
            link.productOptions.forEach(unSelect);
        } else if (selection === 'APP_SCREEN') {
            delete link.link;
            link.appScreenOptions.forEach(unSelect);
        } else if (selection === 'DINING_MENU') {
            delete link.link;
            link.menuOptions.forEach(unSelect);
        } else if (selection === 'LOQUEUE') {
            delete link.link;
            link.loQueueProductOptions.forEach(unSelect);
            link.poisWithRidesOptions.forEach(unSelect);
        } else {
            delete link.link;
        }

        link.type = selection;
        link.appNav = {};
        link.appNavType = event.model[0].appNavType;

        this.$timeout(() => {
            this.refreshLocaleStatus();
        });
    }

    onLinkLinkUpdate(event, index) {
        const link = this.experience.content.links[index];
        if(!link.link[this.locale.current]) {
            link.link[this.locale.current] = ""
            link.link[this.locale.current + this.utmCampaign] = ""
            link.link[this.locale.current + this.utmUrl] = ""
            link.link[this.locale.current + this.showUtm] = 'false'
        }
        this.checkForUtm(event.model)
        if (_.isEmpty(event.model)) {
            _.unset(this.experience, `content.links.${index}.link.${this.locale.current}`);
        } else {
            link.link[this.locale.current] = event.model;
            if(!_.isEmpty(link.link[this.locale.current + this.utmUrl])) {
                this.updateUtmUrl(
                    link.link,
                )
            }
        }
        this.refreshLocaleStatus();
        this.updateAppNav(link);
    }

    checkForUtm(link) {
        if(link.includes('utm_source') || link.includes('utm_medium') || link.includes('utm_campaign')) {
            this.shouldShowUtm = false
        } else {
            this.shouldShowUtm = true
        }
    }

    onUtmCampaignUpdate(event, index) {
        const link = this.experience.content.links[index]
        if (_.isEmpty(event.model)) {
            _.unset(this.experience, `content.links.${index}.link.${this.locale.current + this.utmCampaign}`);
        } else {
            link.link[this.locale.current + this.utmCampaign] = event.model
            this.updateUtmUrl(link.link)
        }
        this.refreshLocaleStatus();
        this.updateAppNav(link)
    }

    updateUtmUrl(link) {
        link[this.locale.current + this.utmUrl] = this.addUtmToLink(
            link[this.locale.current],
            link[this.locale.current + this.utmCampaign]
        )
    }

    onShowUtm(link) {
        if( link.link[this.locale.current + this.showUtm] === 'true'){
            link.link[this.locale.current + this.showUtm] = 'false'
        } else {
            link.link[this.locale.current + this.showUtm] = 'true'
        }
    }

    addUtmToLink(link, utmCampaign) {
        if(link.includes('?')) {
            return link
                + `&utm_source=${this.getUtmSource()}`
                + `&utm_medium=${this.getUtmMedium()}`
                + `&utm_campaign=${utmCampaign}`
        } else {
            return link
                + `?utm_source=${this.getUtmSource()}`
                + `&utm_medium=${this.getUtmMedium()}`
                + `&utm_campaign=${utmCampaign}`
        }
    }

    getUtmSource() {
        const { venueName } = _.find(this.crConfig.customer.venues, { id: this.$state.params.venueId })
        const lowerCase = _.toLower(venueName)
        const lowerCaseUnderscored = _.replace(lowerCase, ' ', '_')
        return lowerCaseUnderscored + '_mobileApp'
    }

    getUtmMedium() {
        if(this.experience.trigger.id.includes('BROADCAST')) {
            return 'broadcast'
        } else {
            return 'automation'
        }
    }

    onProductResourceIdUpdate(event, index) {
        const link = this.experience.content.links[index];

        if(event.model[0].url === undefined) {
            this.onLinkResourceIdUpdate(event, index);
            return;
        }

        link.resourceId = this.updateProductUrlUtmParameters(event.model[0].url)

        this.updateAppNav(link)
    }

    updateProductUrlUtmParameters(productUrl) {
        const urlSplit = _.split(productUrl, '?')
        const paramSplit = _.split(urlSplit[1], '&')
        let returnParams = '?'
        paramSplit.forEach( (param, index) => {
            if(param.includes('utm_source')) {
                if(index === 0) {
                    returnParams += `utm_source=${this.getUtmSource()}`
                } else {
                    returnParams += `&utm_source=${this.getUtmSource()}`
                }
            } else if(param.includes('utm_medium')) {
                if(index === 0) {
                    returnParams += `utm_medium=${this.getUtmMedium()}`
                } else {
                    returnParams += `&utm_medium=${this.getUtmMedium()}`
                }
            } else {
                if(index === 0) {
                    returnParams += param
                } else {
                    returnParams += `&${param}`
                }
            }
        })

        const returnUrl = urlSplit[0] + returnParams
        return returnUrl
    }

    onLinkResourceIdUpdate(event, index) {
        const link = this.experience.content.links[index];

        link.resourceId = event.model[0].id;

        this.updateAppNav(link);
    }

    updateAppNav(link) {
        let appNav;
        if (!link.appNav) {
            link.appNav = {};
        }

        const { venueId } = this.$state.params;

        switch (link.type) {
            case 'WEB': {
                if(this.shouldShowUtm === false || !link.link[this.locale.current + this.utmUrl]) {
                    appNav = new AppNav.Webview(venueId, link.link[this.locale.current]);
                    break;
                } else {
                    appNav = new AppNav.Webview(venueId, link.link[this.locale.current + this.utmUrl]);
                    break;
                }
            }
            case 'COUPON': {
                appNav = new AppNav.CouponDetails(venueId, link.resourceId);
                break;
            }
            case 'DINING_MENU': {
                appNav = new AppNav.DiningMenu(venueId, link.resourceId);
                break;
            }
            case 'EVENT': {
                appNav = new AppNav.EventDetails(venueId, link.resourceId);
                break;
            }
            case 'POI': {
                appNav = new AppNav.PoiDetails(venueId, link.resourceId);
                break;
            }
            case 'PRODUCT': {
                if(link.resourceId.includes('http')) {
                    appNav = new AppNav.Webview(venueId, link.resourceId)
                } else {
                    appNav = new AppNav.ProductDetails(venueId, link.resourceId);
                }
                break;
            }
            case 'APP_SCREEN': {
                switch (link.resourceId) {
                    case 'GUEST_PREF_VENUE':
                        appNav = new AppNav.GuestPreferencesVenue(venueId);
                        break;
                    case 'GUEST_PREF_BRAND':
                        appNav = new AppNav.GuestPreferencesBrand();
                        break;
                    default:
                        break;
                }
                break;
            }
            case 'LOQUEUE': {
                switch (link.appNavType) {
                    case 'loQueueProductDetails':
                        appNav = new AppNav.LoQueueProduct(venueId, link.resourceId);
                        break;
                    case 'loQueueRideDetails': {
                        const selectedPoi = this.poisWithRides.filter((poi) => poi.id === link.resourceId)[0] || {};
                        appNav = new AppNav.LoQueueRide(venueId, link.resourceId, selectedPoi.rideId);
                        break;
                    }
                }
                break;
            }
            default:
                break;
        }

        if (appNav) {
            let url;
            try {
                url = appNav.toString();
            } catch (err) {
                this.crErrorLoggingService.logError('could not serialize appnav', err);
                return;
            }

            link.appNav[this.locale.current] = url;
        } else {
            link.appNav[this.locale.current] = '';
        }
    }

    removeLink(index) {
        this.experience.content.links.splice(index, 1);
        // Shallow copy
        this.experience.content.links = this.experience.content.links.map((n) => n);
        this.dirtyForm();
        this.refreshLocaleStatus();
    }

    addCoupon() {
        this.experience.content.coupons.push({
            couponOptions: _.cloneDeep(this.coupons),
            type: 'COUPON',
            appNav: {},
        });

        this.$timeout(() => {
            this.dirtyForm();
            this.refreshLocaleStatus();
        });
    }

    onCouponSelected(event, index) {
        const coupon = this.experience.content.coupons[index];
        coupon.resourceId = event.model[0].invalid ? null : event.model[0].id;
        this.updateAppNav(coupon);
        this.refreshLocaleStatus();
    }

    removeCoupon(index) {
        this.experience.content.coupons.splice(index, 1);
        this.dirtyForm();
        this.refreshLocaleStatus();
    }

    get couponsValid() {
        const isInvalid = (coupon) => {
            if (!coupon.resourceId) {
                return true;
            }
            if (!this.couponMap[coupon.resourceId] || this.couponMap[coupon.resourceId].invalid) {
                return true;
            }
            return false;
        };
        if (this.experience.content.coupons) {
            const invalidCoupons = this.experience.content.coupons.filter(isInvalid);
            return invalidCoupons.length === 0;
        }

        return true;
    }

    onAppDisplayChange(value) {
        this.experience.content.onHomeScreen = value;
    }

    onModelChanged() {
        this.onChanges({ $event: { locale: this.locale } });
    }
}

export default What;
