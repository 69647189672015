import {
  AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation, ElementRef,
} from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import _ from 'lodash';
import moment from 'moment';
import { constants } from '../..';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'cr-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TimePickerComponent implements OnInit, AfterViewInit {
  readonly placeholder: string;

  readonly timeFormat: string;

  timeSeries: string[];

  @Input()
  customClass: string;

  @Input()
  start = '12:00 AM';

  @Input()
  end = '12:00 AM';

  @Input()
  step = 30;

  @Input()
  hideValidation: boolean;


  @Input()
  isVenueHours = false;

  @ViewChild(InputComponent, { static: true })
  private inputComponent: InputComponent;

  @ViewChild('dropDownMenu', { static: true })
  private dropDownMenu: ElementRef;

  private lastScrollPosition = 0;

  constructor() {
    this.placeholder = constants.placeholder.timePicker;
    this.timeFormat = constants.time.format.pickerModel;
  }

  private static timeValidator(timeFormat: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }

      const timeMoment = moment(control.value, timeFormat, true);
      return !timeMoment.isValid() ? { time: { value: control.value } } : null;
    };
  }

  ngOnInit(): void {
    const startTime = moment(this.start, this.timeFormat);
    const endTime = moment(this.end, this.timeFormat);
    if (endTime.isSameOrBefore(startTime)) {
      endTime.add(1, 'day');
    }
    this.timeSeries = [];
    if(this.isVenueHours) {
      this.timeSeries.push('CLOSED');
    }
    const currentTime = startTime;
    while (currentTime.isBefore(endTime)) {
      this.timeSeries.push(currentTime.format(this.timeFormat));
      currentTime.add(this.step, 'minutes');
    }
  }

  ngAfterViewInit(): void {
    const { control } = this.inputComponent;
    const validators: ValidatorFn[] = control.validator ? [control.validator] : [];
    validators.push(TimePickerComponent.timeValidator(this.timeFormat));
    control.setValidators(validators);
  }

  updateTime(time) {
    this.lastScrollPosition = this.dropDownMenu.nativeElement.scrollTop;
    this.inputComponent.writeValue(time);
  }

  dropDownOpenChange(isOpen: boolean) {
    if (isOpen) {
      setTimeout(() => this.dropDownMenu.nativeElement.scroll(0, this.lastScrollPosition));
    }
  }

  get timeString() {
    return _.get(this.inputComponent, 'value');
  }

  get disabled(): boolean {
    return _.get(this.inputComponent, 'control.disabled');
  }
}
