import { Component, Inject, OnInit } from "@angular/core";
import { Constants, LocalizedText } from "../../../../core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import text from "../../resources/locale/en.json"
import { AnalyticsService, CR_CONSTANTS, ErrorLoggingService, NavigationService, SimpleModalComponent } from "../../../../shared";
import { EntityService } from "../../../../shared/services/entity/entity.service";
import { StateService } from "@uirouter/core";
import { EditOrderHoursDisplay, Hours, HoursList } from "../../constants/hours.types";
import { Form } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap";

@Component({
    selector: 'venue-hours-edit-order',
    templateUrl: './venue-hours-edit-order.component.html',
    styleUrls: ['./venue-hours-edit-order.component.scss']
}) export class VenueHoursEditOrderComponent implements OnInit {
    text: LocalizedText;
    isLoading = false;
    isDataError = false;
    schedule = [];
    venueHours: EditOrderHoursDisplay = [];
    rawPhonePreview: HoursList;
    phonePreviewList = [];
    form: Form;

    constructor(
        @Inject(CR_CONSTANTS) public constants: Constants,
        private crNavigationService: NavigationService,
        private crEntityService: EntityService,
        private state: StateService,
        private modalService: BsModalService,
        private crErrorLoggingService: ErrorLoggingService,
        private crAnalyticsService: AnalyticsService
    ) {
        this.text = text as LocalizedText;
    }

    ngOnInit(): void {
        this.isLoading = true
        this.crEntityService
            .getEntityList(this.constants.entity.types.VENUE_HOURS, { venueId: this.state.params.venueId }, '')
            .then((res) => {
                this.schedule = res.content;
                this.generateEditOrderList();
            }).catch((err) => {
                this.isDataError = true;
                this.crErrorLoggingService.logError('could not retrieve venue hours', err);
            });
        this.crEntityService
            .getVenueHoursByDay(this.constants.entity.types.VENUE_HOURS,
                {
                    venueId: this.state.params.venueId,
                    days: '2'
                },
                '',
                this.state.params.customerId
            ).then((res) => {
                this.rawPhonePreview = res
                this.generatePhonePreviewList();
            }).catch((err) => {
                this.isDataError = true;
                this.crErrorLoggingService.logError('could not retrieve venue hours', err);
            }).finally(() => {
                this.isLoading = false;
            });
    }

    save() {
        this.isLoading = true;

        const data = {
            owner: {
                id: this.state.params.venueId,
                type: 'VENUE'
            },
            ids: this.venueHours.map((hour) => ({ id: hour.id }))
        }

        this.crEntityService.updateRanks(
            data,
            this.constants.entity.types.VENUE_HOURS,
            this.state.params.venueId,
            {}
        ).then(() => {
            const newOrder = this.venueHours.map((hour) => hour.label);
            this.crAnalyticsService.track('Venue hours rank updated', newOrder);
            this.crNavigationService.disableConfirmNavigation();
            this.goBack({
                toast: {
                    msg: this.text.toastMsgOrder,
                    timeToLive: 10,
                }
            });
        }).catch((err) => {
            this.showErrorModal();
            this.crErrorLoggingService.logError('Could not update Venue Hours Ranks', err);
        }).finally(() => {
            this.isLoading = false;
        });
    }

    goBack(params = {}) {
        this.crNavigationService.goBack('client.venue-hours.order', params);
    }

    generateEditOrderList() {
        this.schedule.forEach((venue) => {
            this.venueHours.push({
                id: venue.id,
                label: venue.label
            });
        });
    }

    generatePhonePreviewList() {
        const todaysHours: {
            date: string,
            hours: Hours[]
        } = this.rawPhonePreview.days[1];
        todaysHours.hours.forEach((hour) => {
            const startDate = new Date(hour.schedule.start)
            const endDate = new Date(hour.schedule.end)

            this.phonePreviewList.push({
                label: hour.label,
                startDate: startDate,
                endDate: endDate,
                status: hour.statusLabel
            })
        });
    }

    showErrorModal() {
        this.modalService.show(SimpleModalComponent, {
            initialState: {
                message: this.text.updateError
            },
            backdrop: 'static',
            class: 'cr-modal-size-sm'
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.venueHours, event.previousIndex, event.currentIndex);
    }
}
