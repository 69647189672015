import { Component, Input } from "@angular/core";
import { LocalizedText } from "../../../../../core";
import text from '../../../resources/locale/en.json';
import { ParkHourStatus, WeekDays } from "../../../constants/statuses";
import { VenueHours } from "../../../constants/hours.types";
import { DatePipe } from "@angular/common";
import { StateService } from "@uirouter/core";

@Component({
    selector: 'cr-venue-hours-drop-down',
    templateUrl: './venue-hours-drop-down.component.html',
    styleUrls: ['./venue-hours-drop-down.component.scss']
})
export class VenueHoursDropDownComponent {
    @Input()
    venueHours: {
        label: string;
        venueHours: VenueHours[];
    };

    label: string;
    isOpen = false;
    text: LocalizedText;
    days = []

    constructor(
        private datePipe: DatePipe,
        private state: StateService
    ) {
        this.text = text as LocalizedText
        this.label = ""
        this.days = WeekDays
    }

    getDateByDay(day) {
        const hours = this.venueHours.venueHours.find((hours) => {
            return hours.day === day.date
        })
        return hours.date
    }

    getHoursByDay(day) {
        const hours = this.venueHours.venueHours.find((hours) => {
            return hours.day === day.date
        })
        const formattedStartTime = this.datePipe.transform(hours.startDate, 'shortTime');
        const formattedEndTime = this.datePipe.transform(hours.endDate, 'shortTime');

        return hours.statusLabel === ParkHourStatus.OPEN
            ? `${formattedStartTime} - ${formattedEndTime}`
            : "CLOSED"
    }

    isEditHours() {
        this.state.go('client.venue-hours.edit-hours', { venueLabel: this.venueHours.label })
    }


    getStateParams() {
        return {
            customerId: this.state.params.customerId,
            venueId: this.state.params.venueId,
            venueHours: this.venueHours
        }
    }

    isTemporaryClosure(day) {
        const hours = this.venueHours.venueHours.find((hours) => {
            return hours.day === day.date;
        });
        return hours.isTempClosure;
    }

    isToday(date: Date): boolean {
        const currentDate = new Date();
        return date.setHours(0,0,0,0) == currentDate.setHours(0,0,0,0);
    }
}
