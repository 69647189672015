import { Component, Inject, OnInit } from "@angular/core";
import { StateService } from "@uirouter/core";
import { Constants, LocalizedText } from "../../../../core";
import text from "../../resources/locale/en.json"
import { EntityService } from "../../../../shared/services/entity/entity.service";
import { AnalyticsService, CR_CONSTANTS, ErrorLoggingService, NavigationService, SimpleModalComponent } from "../../../../shared";
import { BsModalService } from "ngx-bootstrap";
import { EditHoursDisplay, UpdatedSchedule } from "../../constants/hours.types";
// import { FormControl } from "@angular/forms";
import { Time, WeekDays } from "../../constants/statuses";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import _ from "lodash";
import { VenueHoursInfoModalComponent } from "./venue-hours-info-modal/venue-hours-info-modal.component";

@Component({
    selector: 'venue-hours-edit-hours',
    templateUrl: './venue-hours-edit-hours.component.html',
    styleUrls: ['./venue-hours-edit-hours.component.scss']
}) export class VenueHoursEditHoursComponent implements OnInit {
    isLoading = false;
    isDataError = false;
    hasInputError = false;
    text: LocalizedText;
    venueHoursLabel: string;
    venueHoursEditTitle: string;
    venueHoursId: string;
    venueHours = [];
    phonePreviewHours = [];
    filteredHours: {
        date: Date,
        day: string,
        hours: Array<any>
    }[];
    errors = {};
    displayHours: EditHoursDisplay = [];
    updatedVenueHours: UpdatedSchedule;
    daysOptions = [];
    editForm: FormGroup;
    hasDislayedTempClosureModal = false;
    hasDisplayedWeeklyModal = false;
    tempClosePriority = 100;
    openingHoursPriority = 2;

    constructor(
        @Inject(CR_CONSTANTS) public constants: Constants,
        private state: StateService,
        private crEntityService: EntityService,
        private crNavigationService: NavigationService,
        private crErrorLoggingService: ErrorLoggingService,
        private crAnalyticsService: AnalyticsService,
        private crModalService: BsModalService,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe
    ) {
        this.text = text as LocalizedText;
        this.venueHoursLabel = this.state.params.venueLabel;
        this.venueHoursEditTitle = `Edit ${this.venueHoursLabel}`;
        this.daysOptions = WeekDays;
        this.filteredHours = [];
        this.daysOptions.forEach((day) => {
            this.displayHours.push({
                day: day.key,
                hours: undefined
            })
        });
    }

    ngOnInit() {
        this.isLoading = true;

        this.crEntityService.getEntityList(
            this.constants.entity.types.VENUE_HOURS,
            { venueId: this.state.params.venueId },
            ''
        ).then((res) => {
            const currentVenue = res.content.find((hour) => {
                return this.venueHoursLabel === hour.label
            })
            this.venueHoursId = currentVenue.id
        }).catch((err) => {
            this.crErrorLoggingService.logError(`Could not update ${this.venueHoursLabel} hours`, err);
            this.isDataError = true;
        });
        this.crEntityService.getVenueHoursByDay(
            this.constants.entity.types.VENUE_HOURS,
            {
                venueId: this.state.params.venueId,
                days: '14'
            },
            '',
            this.state.params.customerId
        ).then((res) => {
            this.venueHours = res.days;
            this.venueHours.shift();
            this.generatePhonePreview();
            this.filterForOnlyVenue();
            this.generateOpeningHours();
            this.generateEditForm();
            this.editForm.patchValue({
                venueLabel: `${this.venueHoursLabel}`,
                ...(this.daysOptions.reduce((controls, opt) => ({
                    ...controls,
                    [`${opt.key}_temp_close`]: this.isClosed(this.filteredHours.find((date) => {
                        return opt.key === date.day;
                    }).hours[0].status)
                }), {})),
                ...(this.daysOptions.reduce((controls, opt) => ({
                    ...controls,
                    [`${opt.key}_open`]: this.getTimePickerDefault(opt.key, "open"),
                    [`${opt.key}_close`]: this.getTimePickerDefault(opt.key, "close")
                }), {}))
            });
        }).catch((err) => {
            this.showErrorModal();
            this.crErrorLoggingService.logError(`Could not retrieve Venue Hours Details for ${this.venueHoursLabel}`, err)
            this.isDataError = true;
        }).finally(() => {
            this.isLoading = false;
        });
    }

    generateEditForm() {
        this.editForm = this.formBuilder.group({
            venueLabel: ['', Validators.required],
            ...(this.daysOptions.reduce((controls, opt) => ({
                ...controls,
                [`${opt.key}_temp_close`]: []
            }), {})),
            ...(this.daysOptions.reduce((controls, opt) => ({
                ...controls,
                [`${opt.key}_open`]: [''],
                [`${opt.key}_close`]: ['']
            }), {}))
        }, { validator: this.createRangeValidator.bind(this) });
    }

    createRangeValidator(fg: FormControl) {
        const errors: any = {};
        this.daysOptions.forEach((day) => {
            const open = fg.get(`${day.key}_open`);
            const close = fg.get(`${day.key}_close`);

            if (open.value || close.value) {
                if (close.value === 'CLOSED') {
                    if (open.value !== 'CLOSED') {
                        errors[`${day.key}_close_error`] = this.text.invalidTimeRange;
                    } else {
                        return;
                    }
                }
                if (open.value === 'CLOSED') {
                    fg.patchValue({
                        [`${day.key}_close`]: 'CLOSED'
                    });
                    this.displayHours.find((display) => {
                        return day.key === display.day
                    }).hours.isPermenantlyClosed = true;
                    fg.patchValue({
                        [`${day.key}_temp_close`]: false
                    });
                } else if (!open.value) {
                    errors[`${day.key}_open_error`] = this.text.invalidTimeRange;
                } else if (!close.value) {
                    errors[`${day.key}_close_error`] = this.text.invalidTimeRange
                } else {
                    if (open.value !== 'CLOSED' && close.value !== 'CLOSED') {
                        const start = this.timeTo24Hour(open.value, 'validation');
                        const end = this.timeTo24Hour(close.value, 'validation');
                        if (start > end) {
                            errors[`${day.key}_close_error`] = this.text.invalidTimeRange;
                        } else {
                            this.displayHours.find((display) => {
                                return day.key === display.day
                            }).hours.isPermenantlyClosed = false;
                        }
                    }
                }
            }
        })
        this.errors = errors;
        return errors
    }

    getDateByDay(key: string) {
        return this.filteredHours.find((hours) => {
            return key === hours.day
        }).date
    }

    showWeeklyModal(key: string) {
        if (!this.hasDisplayedWeeklyModal) {
            this.crModalService.show(VenueHoursInfoModalComponent, {
                initialState: {
                    message: "This change will affect",
                    infoText: `all ${key} in the future.`
                },
                backdrop: 'static',
                class: 'cr-modal-size-sm'
            });
            this.hasDisplayedWeeklyModal = true;
        }
    }

    isToggleOpen(key: string) {
        return this.editForm.get(`${key}_temp_close`).value === false;
    }

    isToggleDisabled(key: string) {
        return this.displayHours.find((day) => {
            return key === day.day
        }).hours.isPermenantlyClosed
    }

    toggleTemporaryClosure(key: string) {
        if (!this.hasDislayedTempClosureModal && !this.editForm.get(`${key}_temp_close`).value) {
            const date = this.filteredHours.find((hour) => {
                return key === hour.day
            });
            this.crModalService.show(VenueHoursInfoModalComponent, {
                initialState: {
                    message: "This change will only affect",
                    infoText: `${this.datePipe.transform(date.date, "fullDate")}.`,
                    subText: "After that date the venue hours will reset to default."
                },
                backdrop: 'static',
                class: 'cr-modal-size-sm'
            });
            this.hasDislayedTempClosureModal = true;
        }
        this.editForm.patchValue({
            [`${key}_temp_close`]: !this.editForm.get(`${key}_temp_close`).value
        })
        this.editForm.markAsDirty();
    }

    save() {
        if (this.editForm.dirty) {
            if (!_.isEmpty(this.errors)) {
                this.hasInputError = true;
                return;
            }
            this.hasInputError = false;
            this.isLoading = true;
            this.generateUpdatedVenueHours();
            const data = this.updatedVenueHours;
            this.isLoading = false;
            this.crEntityService.updateVenueHours(
                this.constants.entity.types.VENUE_HOURS,
                {
                    hoursId: this.venueHoursId
                },
                '',
                this.state.params.customerId,
                data
            ).then(() => {
                const updatedSchedule = data
                this.crAnalyticsService.track(`Venue Hours for ${this.venueHoursLabel} updated`, updatedSchedule);
                this.crNavigationService.disableConfirmNavigation();
                this.goBack({
                    toast: {
                        msg: `'${this.venueHoursLabel}' has been updated`,
                        timeToLive: 10
                    }
                });
            }).catch((err) => {
                this.showErrorModal();
                this.crErrorLoggingService.logError(`Could not update ${this.venueHoursLabel} hours`, err);
            }).finally(() => {
                this.isLoading = false;
            })
        } else {
            this.isLoading = false;
            this.goBack();
        }
    }

    generateUpdatedVenueHours(): string {
        this.tempClosePriority = 100;
        this.openingHoursPriority = 2;
        this.updatedVenueHours = {
            label: this.editForm.get(`venueLabel`).value,
            owner: {
                id: this.state.params.venueId,
                type: "VENUE"
            },
            scheduleGroup: {
                schedules: [{
                    scheduleKey: 'CLOSED',
                    recurrence: "FREQ=DAILY;INTERVAL=1",
                    priority: 1,
                    instances: [{
                        startTime: "2023-10-20T00:00:00",
                        endTime: "2023-10-20T23:59:59"
                    }]
                }]
            },
            url: this.datePipe.transform(new Date(Date.now()), 'long')
        };
        this.daysOptions.forEach((day) => {
            let recurrenceString = `FREQ=WEEKLY;INTERVAL=1;BYDAY=${day.schedule}`
            const date = this.filteredHours.find((date) => {
                return day.key === date.day;
            });
            const displayHours = this.displayHours.find((display) => {
                return day.key === display.day
            });
            if (this.editForm.get(`${day.key}_open`).value !== 'CLOSED') {
                const startTime = this.timeTo24Hour(this.editForm.get(`${day.key}_open`).value, 'updating') as string;
                const endTime = this.timeTo24Hour(this.editForm.get(`${day.key}_close`).value, 'updating') as string;
                this.updatedVenueHours.scheduleGroup.schedules.push({
                    scheduleKey: 'OPEN',
                    recurrence: recurrenceString,
                    priority: this.openingHoursPriority++,
                    instances: [{
                        startTime: startTime,
                        endTime: endTime
                    }]
                });
            } else {
                this.updatedVenueHours.scheduleGroup.schedules.push({
                    scheduleKey: 'CLOSED',
                    recurrence: recurrenceString,
                    priority: this.openingHoursPriority++,
                    instances: [{
                        startTime: "2023-10-20T00:00:00",
                        endTime: "2023-10-20T23:59:59"
                    }]
                });
            }

            if (this.editForm.get(`${day.key}_temp_close`).value === true && !displayHours.hours.isPermenantlyClosed) {
                const dateObj = new Date(date.date)
                const dateString = this.datePipe.transform(dateObj, "yyyy-MM-dd");
                const untilString = this.datePipe.transform(
                    new Date(dateObj.getTime() + (24 * 60 * 60 * 1000)), "yyyyMMdd"
                );
                recurrenceString += `;UNTIL=${untilString}`
                this.updatedVenueHours.scheduleGroup.schedules.push({
                    scheduleKey: 'CLOSED',
                    recurrence: recurrenceString,
                    priority: this.tempClosePriority++,
                    instances: [{
                        startTime: dateString + "T00:00:00",
                        endTime: dateString + "T23:59:59"
                    }]
                });
            }
        });
        return '';
    }

    generatePhonePreview() {
        const todaysHours = this.venueHours[0].hours;
        todaysHours.forEach((hour) => {
            const startDate = new Date(hour.schedule.start)
            const endDate = new Date(hour.schedule.end)

            this.phonePreviewHours.push({
                label: hour.label,
                startDate: startDate,
                endDate: endDate,
                status: hour.statusLabel
            })
        });
    }

    generateOpeningHours() {
        this.displayHours.forEach((display) => {
            const currentDayIndex = this.filteredHours.findIndex((date) => {
                return display.day === date.day
            })
            display.hours = {
                isClosed: this.isClosed(this.filteredHours[currentDayIndex].hours[0].status),
                isPermenantlyClosed: this.isPermanentlyClosed(
                    this.filteredHours[currentDayIndex].hours[0].status,
                    this.filteredHours[currentDayIndex + 7].hours[0].status
                ),
                openTime: this.datePipe.transform(this.filteredHours[currentDayIndex].hours[0].schedule.start, "shortTime"),
                closeTime: this.datePipe.transform(this.filteredHours[currentDayIndex].hours[0].schedule.end, "shortTime")
            }
        })
    }

    isClosed(status: string) {
        return status === 'CLOSED';
    }

    isPermanentlyClosed(firstWeek: string, secondWeek: string) {
        if (this.isClosed(firstWeek) && secondWeek === firstWeek) {
            return true;
        } else {
            return false;
        }
    }

    getTimePickerDefault(key: string, picker: "open" | "close") {
        const currentDay = this.displayHours.find((date) => {
            return key === date.day
        })
        if (currentDay.hours.isPermenantlyClosed) {
            return 'CLOSED'
        } else if (picker === "open") {
            if (currentDay.hours.isClosed && !currentDay.hours.isPermenantlyClosed) {
                const index = this.filteredHours.findIndex((date) => {
                    return key === date.day
                });
                return this.datePipe.transform(this.filteredHours[index + 7].hours[0].schedule.start, "shortTime");
            }
            return currentDay.hours.openTime
        } else {
            if (currentDay.hours.isClosed && !currentDay.hours.isPermenantlyClosed) {
                const index = this.filteredHours.findIndex((date) => {
                    return key === date.day
                });
                return this.datePipe.transform(this.filteredHours[index + 7].hours[0].schedule.end, "shortTime");
            }
            return currentDay.hours.closeTime
        }
    }

    filterForOnlyVenue() {
        this.venueHours.forEach((dateHours) => {
            const rawDate = dateHours.date.replace(/-/g, '/');
            const date = new Date(rawDate);
            this.filteredHours.push({
                date: date,
                day: date.toLocaleDateString('en-US', { weekday: "long" }).toLowerCase(),
                hours: dateHours.hours.filter((venue) => {
                    return this.venueHoursLabel === venue.label
                })
            })
        })
    }

    showErrorModal() {
        this.crModalService.show(SimpleModalComponent, {
            initialState: {
                message: this.text.updateError
            },
            backdrop: 'static',
            class: 'cr-modal-size-sm'
        });
    }

    goBack(params = {}) {
        this.crNavigationService.goBack('client.venue-hours.order', params)
    }

    timeTo24Hour(time: string, purpose: 'validation' | 'updating') {
        const [hour, minutes, amPm] = time.split(/(?::|\s)/);
        let totalTime = parseInt(hour);
        totalTime = totalTime === 12 ? 0 : totalTime;
        if (minutes) {
            totalTime += (parseInt(minutes) / 60);
        }
        if (amPm?.toLowerCase() === Time.PM) {
            totalTime += 12;
        }
        if (purpose === 'updating') {
            if (totalTime < 10) {
                return `2023-10-20T0${Math.floor(totalTime)}:${minutes}:00`
            } else {
                return `2023-10-20T${Math.floor(totalTime)}:${minutes}:00`
            }
        }
        return totalTime;
    }
}
