<div class="cr-edit details-page">
    <cr-title-bar [label]="title" (onClick)="goBack()">
        <div titleBarCenter>
            <cr-form-submission-error
                    *ngIf="hasDataError"
                    [message]="text.formSubmissionErrorText"
            ></cr-form-submission-error>
        </div>
        <div *ngIf="!isLoading && !isSaving" titleBarRight>
            <button
                    (click)="onCancel()"
                    class="btn btn-nobg"
                    data-global-qa-id="manage-cancel"
            >
                {{ text.cancel }}
            </button>
            <button
                    *ngIf="!isEdit"
                    class="btn btn-primary"
                    (click)="onSave()"
                    data-global-qa-id="manage-save"
            >
                {{ text.save }}
            </button>
        </div>
    </cr-title-bar>

    <div>
        <div class="text-center cr-page-padding-top"
             *ngIf="isLoading || isSaving">
            <cr-spinner></cr-spinner>
        </div>
    </div>

    <div class="cr-list-item" *ngIf="!isLoading && !isSaving">
        <form [formGroup]="editForm"
              novalidate>

            <section>
                <div class="title">
                    <h1><input formControlName="title"
                        placeholder = "{{ title }}"
                        type="text"
                    ></h1>
                </div>
                <label>
                    {{text.poiTitle}} : {{ poi.title }}<br>
                    {{text.poiId}} : {{ poi.id }}
                </label>
            </section>

            <section>
                <hr class="details-divider"/>
                <cr-section-heading>{{ text.status }}</cr-section-heading>
                <div class="automatic-status">
                    <div>{{ text.automaticStatus }}</div>
                    <cr-toggle [valueMessages]="text.activeInactiveToggleOptions"
                               [isOn]="poiExtension.autoStatus"
                               (onToggle)="onToggleAutoStatus()"
                    ></cr-toggle>
                </div>

                <div class="status-label">
                    <div class="text-capitalize">
                        <cr-dropdown
                                class="header-item"
                                [label]="text.statusText"
                                [menuItems]="statusList"
                                placeholder="-"
                                (onSelect)="onChangeOperationStatus($event.$event.model[0].id)"
                                mode="select"
                        ></cr-dropdown>
                    </div>
                </div>

                <div class="detail-item-heading"> {{ text.status }} </div>
                <div class="text-capitalize"> {{ operationStatus?.status }} </div>
                <div class="detail-item-heading"> {{ text.statusColor }} </div>
                <div>
                    <span class="icon-cr-circle"
                          style="border-color: {{ operationStatus?.color }}"
                          *ngIf="operationStatus?.status?.toLowerCase() === text.open || operationStatus?.status?.toLowerCase() === text.hold"></span>
                    <span class="icon-cr-close"
                          style="color: {{ operationStatus?.color }}"
                          *ngIf="operationStatus?.status?.toLowerCase() === text.closed"></span>
                    <span class="text-capitalize" *ngIf="operationStatus?.color?.toLowerCase() === text.redHexColor"> {{ text.red }} </span>
                    <span class="text-capitalize" *ngIf="operationStatus?.color?.toLowerCase() === text.greenHexColor"> {{ text.green }} </span>
                    <span class="text-capitalize" *ngIf="operationStatus?.color?.toLowerCase() === text.blueHexColor"> {{ text.blue }} </span>
                    <span class="text-capitalize" *ngIf="operationStatus?.color?.toLowerCase() === text.yellowHexColor"> {{ text.yellow }} </span>
                </div>
            </section>

            <section>
                <hr class="details-divider"/>
                <cr-section-heading> {{ text.details }} </cr-section-heading>

                <div class="mountain-area">
                    <cr-input formControlName="mountainArea"
                              label="{{ text.mountainArea }}"
                              placeholder="{{ text.mountainArea }}"
                              type="text"
                    ></cr-input>
                </div>

                <div class="type">
                    <div class="detail-item-heading"> {{ text.type }} </div>
                    <div class="text-capitalize"> {{ poiExtension.SNOAAType }} </div>
                </div>

                <div class="lift-icon" *ngIf="isLift">
                    <cr-dropdown class="header-item"
                                 label="{{ text.icon }}"
                                 [menuItems]="iconTypeOptions"
                                 (onSelect)="selectedIconType = $event.$event.model[0].id; onChangeIcon();"
                                 mode="select"
                    ></cr-dropdown>
                    <div
                            class="sticker-image square-image"
                            [ngStyle]="{'background-image': 'url(\'' + iconSrc + '\')'}"
                    >
                        <div class="cr-flex-center" *ngIf="!iconSrc">
                            <span class="icon-cr-image"></span>
                        </div>
                    </div>
                </div>

                <div class="trail-icon" *ngIf="isTrail">
                    <cr-dropdown class="header-item"
                                 label="{{ text.icon }}"
                                 [menuItems]="trailIconOptions"
                                 (onSelect)="selectedTrailIcon = $event.$event.model[0].id; onChangeIcon();"
                                 mode="select"
                    ></cr-dropdown>
                    <div
                            class="sticker-image square-image"
                            [ngStyle]="{'background-image': 'url(\'' + iconSrc + '\')'}"
                    >
                        <div class="cr-flex-center" *ngIf="!iconSrc">
                            <span class="icon-cr-image"></span>
                        </div>
                    </div>
                </div>

                <div class="difficulty" *ngIf="isTrail">
                    <cr-dropdown class="header-item"
                                 [label]="text.difficulty"
                                 [menuItems]="difficultyOptions"
                                 (onSelect)="selectedDifficultyId = $event.$event.model[0].id"
                                 mode="select"
                    ></cr-dropdown>
                </div>

                <div class="wait-time">
                    <cr-input formControlName="waitTime"
                              label="{{ text.waitTime }}"
                              placeholder="{{ text.waitTime }}"
                              type="number"
                              icon=""
                    ></cr-input>
                    <span class="clear"
                          *ngIf="editForm.value.waitTime"
                          (click)="editForm.patchValue({waitTime: ''})">
                        <cr-icon class="icon-cr-close"></cr-icon>
                    </span>
                </div>

                <div class="glades" *ngIf="isTrail">
                    <div>{{ text.glades }}</div>
                    <cr-toggle [valueMessages]="text.booleanToggleOptions"
                               [isOn]="isGlades"
                               (onToggle)="isGlades = !isGlades"
                    ></cr-toggle>
                </div>

                <div class="grooming" *ngIf="isTrail">
                    <div>{{ text.grooming }}</div>
                    <cr-toggle [valueMessages]="text.booleanToggleOptions"
                               [isOn]="isGrooming"
                               (onToggle)="isGrooming = !isGrooming"
                    ></cr-toggle>
                </div>

                <div class="moguls" *ngIf="isTrail">
                    <div>{{ text.moguls }}</div>
                    <cr-toggle [valueMessages]="text.booleanToggleOptions"
                               [isOn]="isMoguls"
                               (onToggle)="isMoguls = !isMoguls"
                    ></cr-toggle>
                </div>

                <div class="night-skiing" *ngIf="isTrail">
                    <div>{{ text.nightSkiing }}</div>
                    <cr-toggle [valueMessages]="text.booleanToggleOptions"
                               [isOn]="isNightSkiing"
                               (onToggle)="isNightSkiing = !isNightSkiing"
                    ></cr-toggle>
                </div>

                <div class="snow-making" *ngIf="isTrail">
                    <div>{{ text.snowMaking }}</div>
                    <cr-toggle [valueMessages]="text.booleanToggleOptions"
                               [isOn]="isSnowMaking"
                               (onToggle)="isSnowMaking = !isSnowMaking"
                    ></cr-toggle>
                </div>
            </section>

            <section *ngIf="poiExtension?.hours">
                <hr class="details-divider"/>
                <cr-section-heading>{{ text.hours }}</cr-section-heading>
                <div class="card">
                    <div class="card-header">
                        {{ text.openingHours }}
                    </div>
                    <div class="card-body">
                        <div class="row" *ngFor="let dayOption of daysOptions">
                            <div class="col-xs-12">
                                <div class="col-xs-12">{{ dayOption.label }}</div>
                            </div>
                            <div class="col-xs-12">
                                <div class="col-xs-4">
                                    <span class="label">{{ text.start }}</span>
                                </div>
                                <div class="col-xs-4 row">
                                    <span class="label">{{ text.end }}</span>
                                </div>
                            </div>
                            <div class="col-xs-12">
                                <div class="col-xs-4">
                                    <span class="error"
                                          *ngIf="editForm.errors && editForm.errors[dayOption.key + '_open_error']">
                                        <span class="icon-cr-error"></span>
                                        {{editForm.errors && editForm.errors[dayOption.key + '_open_error']}}
                                    </span>
                                    <cr-time-picker
                                            formControlName="{{dayOption.key}}_open"
                                            step="15"
                                    ></cr-time-picker>
                                </div>
                                <div class="col-xs-4 row">
                                    <span class="error"
                                          *ngIf="editForm.errors && editForm.errors[dayOption.key + '_close_error']">
                                        <span class="icon-cr-error"></span>
                                        {{editForm.errors && editForm.errors[dayOption.key + '_close_error']}}
                                    </span>
                                    <cr-time-picker
                                            formControlName="{{dayOption.key}}_close"
                                            step="15"
                                    ></cr-time-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </div>
</div>
