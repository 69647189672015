export const Time = {
    PM: 'pm',
    AM: 'am'
};

export const WeekDays = [
    {key:'sunday', date: 'Sunday,', weekly: 'Sundays', schedule: 'SU'},
    {key:'monday', date: 'Monday,', weekly: 'Mondays', schedule: 'MO'},
    {key:'tuesday', date: 'Tuesday,', weekly:  'Tuesdays', schedule: 'TU'},
    {key:'wednesday', date: 'Wednesday,', weekly: 'Wednesdays', schedule: 'WE'},
    {key:'thursday', date: 'Thursday,', weekly: 'Thursdays', schedule: 'TH'},
    {key:'friday', date: 'Friday,', weekly: 'Fridays', schedule: 'FR'},
    {key: 'saturday', date: 'Saturday,', weekly: 'Saturdays', schedule: 'SA'}
]

export const ParkHourStatus = {
    OPEN: 'Open',
    CLOSED: 'Closed'
}
