<div class="modal-body cr-modal-contents">
    <span>{{ message }}</span><br>
    <span><strong>{{ infoText }}</strong></span><br>
    <span class="modal-subtext">{{ subText }}</span>
</div>
<div class="modal-footer">
    <button
        class="btn btn-primary"
        type="button"
        data-local-qa-id="submit"
        (click)="submit()"
    >
        {{ getSubmitText() }}
    </button>
</div>
