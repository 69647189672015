<section class="cr-venue-hours-edit-order">
    <cr-title-bar
        [label]="this.text.venueHoursSettingsTitle"
        icon="icon-cr-time"
    >
        <div titleBarRight>
            <button class="btn btn-nobg" (click)="this.goBack()">
                {{ this.text.cancel }}
            </button>
            <button class="btn btn-primary" (click)="this.save()">
                {{ this.text.save }}
            </button>
        </div>
    </cr-title-bar>

    <section>
        <div *ngIf="this.isLoading" class="preloader">
            <cr-spinner class="spinner" smallSpinner="true"></cr-spinner>
        </div>

        <cr-data-error
            *ngIf="this.isDataError"
        ></cr-data-error>

        <div
            class="no-results cr-flex-center cr-page-padding-top"
            *ngIf="!this.isLoading && !this.isDataError && !this.venueHours.length"
        >
        </div>
    </section>

    <section class="content-container" *ngIf="!this.isLoading && !this.isDataError">
        <div class="instructions">
            <span class="text">{{ this.text.editOrderInstruction }}</span>
        </div>

        <section class="hours-list-container" cdkDropList>
            <ul
                *ngIf="!this.isLoading"
                cdkDropList
                (cdkDropListDropped)="this.drop($event)"
            >
                <li *ngFor="let venueHour of this.  venueHours; let i = $index">
                    <div cdkDrag>
                        <div class="inline-block" >
                            {{ venueHour.label }}
                        </div>
                        <div
                            class="icon-cr-sort cursor-move inline-block"
                            cdkDragHandle
                        ></div>
                    </div>
                </li>
            </ul>
        </section>

        <section class="phone-preview-container">
            <cr-phone *ngIf="!this.isDataError && !this.isLoading">
                <cr-venue-hours-phone-preview
                    [phonePreviewList]="this.phonePreviewList"
                >
                </cr-venue-hours-phone-preview>
            </cr-phone>
        </section>
    </section>
</section>
