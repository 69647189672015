<section class="cr-venue-hours-edit-hours">
    <cr-title-bar
        [label]="this.text.venueHoursEditHoursTitle"
        icon="icon-cr-time"
    >
        <div titleBarCenter>
            <cr-form-submission-error
                    *ngIf="this.hasInputError"
                    [message]="text.formSubmissionErrorText"
            ></cr-form-submission-error>
        </div>
        <div titleBarRight>
            <button class="btn btn-nobg" (click)="this.goBack()">
                {{ this.text.cancel }}
            </button>
            <button class="btn btn-primary" (click)="this.save()">
                {{ this.text.save }}
            </button>
        </div>
    </cr-title-bar>

    <section>
        <div *ngIf="this.isLoading" class="preloader">
            <cr-spinner class="spinner" smallSpinner="true"></cr-spinner>
        </div>

        <cr-data-error *ngIf="this.isDataError"></cr-data-error>

        <div
            class="no-results cr-flex-center cr-page-padding-top"
            *ngIf="!this.isLoading && !this.isDataError && !this.venueHours"
        >
        </div>
    </section>

    <section class="content-container" *ngIf="!this.isLoading && !this.isDataError">
        <form [formGroup]="this.editForm" novalidate class="form-container">
            <div class="title">
                <cr-form-label-error
                    class="venue-label"
                    for="venueLabel"
                >
                    {{ this.text.editHoursVenueLabel }} *
                </cr-form-label-error>
                <input
                    class="venue-input"
                    formControlName="venueLabel"
                    [placeholder]="this.venueHoursLabel"
                    type="text"
                />
            </div>
            <hr class="details-divider" />

            <section>
                <div class="section-title">
                    {{ this.text.venueClosureTitle}}
                </div>
                <div class="section-subtitle">
                    <span>{{ this.text.venueClosureCopy }}</span>
                </div>
                <div class="hours-list-container">
                    <div *ngFor="let day of this.daysOptions">
                        <div class="day-of-week">
                            <span class="day">
                                {{ day.date }}
                                <span class="date">
                                    {{ this.getDateByDay(day.key) | date: 'MMMM d'}}
                                </span>
                            </span>
                        </div>
                        <div class="input-container">
                            <cr-toggle
                                name="{{day.key}}_temp_close"
                                [valueMessages]="this.text.openClosedToggleOptions"
                                [disabled]="this.isToggleDisabled(day.key)"
                                [isOn]="this.isToggleOpen(day.key)"
                                (onToggle)="this.toggleTemporaryClosure(day.key)">
                            </cr-toggle>
                        </div>
                    </div>
                </div>
            </section>

            <hr class="details-divider" />

            <section>
                <div class="section-title">
                    {{ this.text.generalVenueHoursTitle }}
                </div>
                <div class="section-subtitle">
                    <span>{{ this.text.generalVenueHoursCopy }}</span>
                </div>
                <div class="hours-list-container">
                    <div *ngFor="let day of this.daysOptions">
                        <div class="day-of-week">
                            <span class="day">
                                {{ day.weekly }}
                            </span>
                        </div>
                        <div class="time-picker-container">
                            <div class="open">
                                <cr-time-picker
                                    formControlName="{{day.key}}_open"
                                    step="15"
                                    [isVenueHours]="true"
                                    [hideValidation]="true"
                                    (click)="this.showWeeklyModal(day.weekly)"
                                ></cr-time-picker>
                                <span class="error"
                                    *ngIf="editForm.errors && editForm.errors[day.key + '_open_error']"
                                >
                                    <span class="icon-cr-error"></span>
                                    {{editForm.errors && editForm.errors[day.key + '_open_error']}}
                                </span>
                            </div>
                            <span class="to">to</span>
                            <div class="close">
                                <cr-time-picker
                                    formControlName="{{day.key}}_close"
                                    step="15"
                                    [isVenueHours]="true"
                                    [hideValidation]="true"
                                    (click)="this.showWeeklyModal(day.weekly)"
                                ></cr-time-picker>
                                <span class="error"
                                    *ngIf="editForm.errors && editForm.errors[day.key + '_close_error']"
                                >
                                <span class="icon-cr-error"></span>
                                    {{editForm.errors && editForm.errors[day.key + '_close_error']}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <hr class="details-divider">

            <div class="required-field">
                {{ this.text.requiredField }}
            </div>
        </form>

        <section class="phone-preview-container">
            <cr-phone>
                <cr-venue-hours-phone-preview
                    [phonePreviewList]="this.phonePreviewHours"
                ></cr-venue-hours-phone-preview>
            </cr-phone>
        </section>
    </section>
</section>
